<template>
  <div class="mx-a mb-16">
    <div class="current-bar pyx mb-16">
      <router-link
        v-for="nav in routerList"
        :key="nav.id"
        :to="nav.path"
        :class="`depth2 fm-16 ${G_NAV_ID.startsWith(nav.id) ? 'selected' : ''}`"
      >
        {{ nav.title }}
      </router-link>
    </div>
    <!-- 파트너 정보 -->
    <div class="info-container" v-if="partnerInfo">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">파트너정보</span>
        </div>
      </div>
      <!-- 등록일시 -->
      <div class="info-content-wrap">
        <span class="content-category">등록일시</span>
        <span>{{ partnerInfo.regDate }}</span>
      </div>
      <!-- 파트너코드 -->
      <div class="info-content-wrap">
        <span class="content-category">파트너코드</span>
        <span>{{ partnerInfo.partnerEsntlId }}</span>
      </div>
      <!-- 분야 -->
      <div class="info-content-wrap">
        <span class="content-category">분야</span>
        <span
          >{{ partnerInfo.highSpecialty }} >
          {{ partnerInfo.lowSpecialty }}</span
        >
      </div>
      <!-- 유형 -->
      <div class="info-content-wrap">
        <span class="content-category">계약유형</span>
        <span>{{ getCompStatus(partnerInfo.compStatus) }}</span>
      </div>
      <!-- 파트너명 -->
      <div class="info-content-wrap">
        <span class="content-category">파트너명</span>
        <span>{{ partnerInfo.compName }}</span>
      </div>
      <!-- 아이디 -->
      <div class="info-content-wrap">
        <span class="content-category">아이디</span>
        <span>{{ partnerInfo.email }}</span>
      </div>
    </div>
    <!-- 카테고리 -->
    <div class="content-section mb-16" style="margin-top: 10px">
      <div class="content-section-header">
        <div class="d-flex">
          <span class="fs-16">카테고리</span>
          <span class="fs-16" style="color: #f1431d">*</span>
        </div>
      </div>
      <div class="content-section-content">
        <div>
          <span class="fs-14"
            >선택한 카테고리 : {{ getHighCateName }}
            <span v-if="getHighCateName !== ''">></span>
            <span style="color: #2CCDC3">{{ getCateName }}</span></span
          >
        </div>
        <div class="mt-16 d-flex">
          <div class="content-category-select">
            <div
              :class="`content-category-select-item${
                sendParams.highCategoryEsntlId === highCate.esntlId
                  ? '-active'
                  : ''
              }`"
              v-for="(highCate, index) in highCateList"
              :key="'highCate' + index"
              @click="selectHighCate(highCate.esntlId)"
            >
              {{ highCate.title }}
            </div>
          </div>
          <img
            class="content-category-arrow"
            src="@/assets/image/arrow_right_green.svg"
            alt="arrow"
          />
          <div class="content-category-select">
            <div
              :class="`content-category-select-item${
                sendParams.lowCategoryEsntlId === cate.esntlId ? '-active' : ''
              }`"
              v-for="(cate, index) in cateList"
              :key="'cate' + index"
              @click="selectCate(cate)"
            >
              {{ cate.title }}
            </div>
          </div>
        </div>
        <p class="fs-12 mt-16" style="color: #2CCDC3">
          코스내용과 맞지 않는 카테고리에 등록할 경우 본사에서 연락이 갈 수
          있습니다.
        </p>
        <p class="fs-12 mb-16" style="color: #2CCDC3">
          신중하게 선택 후 작성해 주세요
        </p>
      </div>
    </div>
    <!-- 코스제목 -->
    <div class="content-section mb-16" style="margin-top: 10px">
      <div class="content-section-header">
        <div class="d-flex">
          <span class="fs-16">결제 항목 설정</span>
          <span class="fs-16" style="color: #f1431d">*</span>
        </div>
      </div>
      <div class="content-section-content">
        <button-common
          class="mt-8"
          :size="SIZE_T"
          clr="w"
          @click="addPaymentGroup"
        >
          + 추가
        </button-common>
        <div class="course-wrap">
          <draggable v-model="grpArr" handle=".grp-bars">
            <transition-group>
              <payment-grp
                v-for="(v, i) in grpArr"
                :key="v.id"
                :item="v.payment"
                :name="`${v.name} ${i + 1}`"
                @remove="onRemoveGrp(v.id)"
                @input="onInputPayment($event, i)"
                group="group"
              />
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>

    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div>
        <span class="error mr-32" v-if="!isCheckAdd">입점계약이 안된 파트너 업체 입니다.</span>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="goCompanyRegList"
          >돌아가기</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          style="color: #f1431d"
          @click="requestAllDelete"
          >전체 삭제</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!isCheckAdd || !checkValid"
          @click="requestEdit"
          >수정</button-common
        >
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import PaymentGrp from "@/components/ui/payment/PaymentGrp";

export default {
  name: "CompanyPaymentStatus",
  mixins: [],
  components: {
    draggable,
    PaymentGrp,
  },

  /*** data ***/
  data() {
    return {
      isCheckAdd: true,
      titleType: "NEW",
      highCateList: [],
      cateList: [],
      grpArr: [],
      sendParams: {
        esntlId: "",
        partnerEsntlId: "",
        highCategoryEsntlId: "",
        lowCategoryEsntlId: "",
        serviceList: [],
      },
      partnerInfo: null,
      isReset: false,
      toRoute: {
        path: "",
        meta: "",
      },
      routerList: [],
    };
  },

  /*** created ***/
  async created() {
    await this.$store.dispatch("A_NAV_SHOW", false);
  },

  /*** mounted ***/
  async mounted() {
    this.routerList.push({
      id: "11-2-1-3",
      path: `/company/payment/approve/${this.$route.params.id}`,
      title: "신청 항목",
    });
    this.routerList.push({
      id: "11-2-1-4",
      path: `/company/payment/status/${this.$route.params.id}`,
      title: "등록 현황",
    });
    this.routerList.push({
      id: "11-2-1-5",
      path: `/company/payment/prev/${this.$route.params.id}`,
      title: "지난 등록 내역",
    });
    await this.checkAdd();
    await this.getRegData();
    await this.getHighCategoryList();
  },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_NAV_ID"]),
    getHighCateName() {
      let highCateList = this.highCateList.filter(
        (item) => this.sendParams.highCategoryEsntlId === item.esntlId
      );
      return highCateList.length > 0 ? highCateList[0].title : "";
    },
    getCateName() {
      let cateList = this.cateList.filter(
        (item) => this.sendParams.lowCategoryEsntlId === item.esntlId
      );
      return cateList.length > 0 ? cateList[0].title : "";
    },
    checkValid() {
      return (
        this.grpArr.filter((item) => item.payment.name.length > 0).length > 0 &&
        this.sendParams.highCategoryEsntlId &&
        this.sendParams.lowCategoryEsntlId
      );
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    /** @ 계약 내역 체크 ***/
    async checkAdd() {
      const rs = await this.$axios({
        url: "/admin/service/check",
        params: { partnerEsntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.isCheckAdd = true;
      } else {
        this.isCheckAdd = false;
      }
    },

    /** @ 등록 현황 조회 ***/
    async getRegData() {
      const rs = await this.$axios({
        url: "/admin/service/reg/data",
        params: { partnerEsntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.setData(rs.resultData);
        console.log("등록 현황 조회 완료");
      } else {
        console.log("등록 현황 조회 실패");
      }
    },

    /** @ 상세조회 데이터 세팅 ***/
    setData(data) {
      if (data) {
        this.partnerInfo = data;
        this.sendParams.esntlId = data.esntlId;
        this.sendParams.partnerEsntlId = this.$route.params.id;
        this.sendParams.highCategoryEsntlId = data.highCategoryEsntlId;
        this.sendParams.lowCategoryEsntlId = data.lowCategoryEsntlId;
        // 서비스 리스트 순서 정렬
        this.grpArr = [];
        data.serviceList.forEach((item) => {
          const content = JSON.parse(JSON.stringify(item));
          content.itemList.sort((a, b) => {
            return a.numberOrder - b.numberOrder;
          });
          this.grpArr.push({
            id: this.$nnid(),
            name: `항목`,
            payment: content,
          });
        });
        this.grpArr.sort((a, b) => {
          return a.payment.numberOrder - b.payment.numberOrder;
        });
      }
    },

    /** @ 카테고리 상위키 조회 ***/
    async getHighCategoryList() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: "" },
      });
      if (rs.result === "SUC") {
        this.highCateList = rs.resultList;
        if (this.sendParams.highCategoryEsntlId) {
          this.selectHighCate(this.sendParams.highCategoryEsntlId);
        }
        console.log("상위 카테고리 조회 완료");
      } else {
        console.log("상위 카테고리 조회 실패");
      }
    },

    /** @ 카테고리 하위키 조회 ***/
    async selectHighCate(id) {
      this.sendParams.highCategoryEsntlId = id;
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: id },
      });
      if (rs.result === "SUC") {
        this.cateList = rs.resultList;
        console.log("하위 카테고리 조회 완료");
      } else {
        console.log("하위 카테고리 조회 실패");
      }
    },

    selectCate(cate) {
      this.sendParams.lowCategoryEsntlId = cate.esntlId;
    },

    /** @ 수정 신청 ***/
    async doEdit() {
      this.sendParams.serviceList = [];
      this.grpArr.forEach((item, index) => {
        const payment = JSON.parse(JSON.stringify(item.payment));
        payment.numberOrder = index;
        payment.itemList.forEach((data, dataIndex) => {
          data.numberOrder = dataIndex;
        });
        if (payment.name.length > 0) {
          this.sendParams.serviceList.push(payment);
        }
      });
      this.sendParams.partnerEsntlId = this.$route.params.id;
      const rs = await this.$axios({
        url: "/admin/service/edit",
        params: this.sendParams,
      });
      if (rs.result === "SUC") {
        this.goCompanyRegList();
      } else {
        console.log("수정 신청 실패");
      }
    },

    /** @ 전체삭제 ***/
    async doAllDelete() {
      const rs = await this.$axios({
        url: "/admin/service/all/delete",
        params: { partnerEsntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.goCompanyRegList();
      } else {
        console.log("전체 삭제 실패");
      }
    },

    resetParams() {
      this.titleType = "NEW";
      this.partnerInfo = null;
      this.grpArr = [];
      this.cateList = [];
      this.sendParams = {
        highCategoryEsntlId: "",
        lowCategoryEsntlId: "",
        serviceList: [],
      };
    },

    // 결제 그룹 추가
    addPaymentGroup() {
      const content = {
        name: "", // 이름
        type: "", // 유형
        price: "", // 할인전 금액
        minCnt: "", // 월 최소 횟수
        maxCnt: "", // 월 최대 횟수
        itemList: [], // 할인율 배열
        penalty: "", // 해지위약금
      };
      this.grpArr.push({ id: this.$nnid(), name: `항목`, payment: content });
    },

    /**@ 모달 CB ***/
    // 모달콜백: 취소
    goPage() {
      this.isReset = true;
      this.$router.push({
        path: this.toRoute.path,
        meta: { id: this.toRoute.meta },
      });
    },

    goCompanyRegList() {
      this.$router.back();
    },

    async requestEdit() {
      await this.$openConfirm(
        {
          bodyTx: "결제항목을 수정 하시겠습니까?",
          btnScndNm: "취소",
          btnPrmyNm: "수정",
          cbPrmy: this.doEdit,
          data: {},
        },
        this
      );
    },

    async requestAllDelete() {
      await this.$openConfirm(
        {
          bodyTx: "결제항목을 전체 삭제 하시겠습니까?",
          btnScndNm: "취소",
          btnPrmyNm: "전체삭제",
          cbPrmy: this.doAllDelete,
          data: {},
        },
        this
      );
    },

    // 그룹 삭제
    async onRemoveGrp(id) {
      await this.$openConfirm(
        {
          bodyTx: "해당 그룹을 삭제하시겠습니까?",
          cbPrmy: this.cbDeleteGrp,
          data: { id },
        },
        this
      );
    },

    // 모달콜백: 그룹 삭제
    cbDeleteGrp(val) {
      this.grpArr = this.grpArr.filter((item) => item.id !== val.id);
    },

    // 코스 데이터 입력시
    onInputPayment(payment, idx) {
      this.grpArr[idx].payment = payment;
    },

    getCompStatus(type) {
      switch (type) {
        case "C":
          return "사업자";
        case "P":
          return "개인";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-circle {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #515466;
  margin-right: 5px;
}
.content-info {
  font-size: 12px;
  font-weight: 400;
  color: #515466;
}
.content-section-header {
  height: 53px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8dae5;
}
.content-section-content {
  padding: 8px 32px;
}
.content-category-select {
  padding: 16px 16px 0 16px;
  width: 510px;
  height: 160px;
  border: 1px solid #d8dae5;
  overflow-y: scroll;
}
.content-category-select-item {
  width: 100%;
  height: 36px;
  padding: 8px;
  color: #828699;
  font-size: 14px;
  cursor: pointer;
}
.content-category-select-item-active {
  width: 100%;
  height: 36px;
  padding: 8px;
  color: #2CCDC3;
  font-size: 14px;
  cursor: pointer;
  background: #f1f2f8;
}
.content-category-arrow {
  margin-left: 40px;
  margin-right: 40px;
}
.course-wrap {
  padding: 16px 0;
}
.coach-table-null {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  border: 1px solid #d8dae5;
}
.search-table-wraaper {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 320px;
  overflow-y: scroll;
}
.search-table-wraaper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.search-table-wraaper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
.error {
  color: #f1431d;
  font-size: 14px;
}
</style>
